import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/Auth';
import ConnectWalletPopup from './ConnectWalletPopup';
// function openNav() {
//   document.getElementById('mySidenav').style.width = '100%';
// }

function closeNav() {
  document.getElementById('mySidenav').style.width = '0%';
}
export default function NavBar() {
  const history = useHistory();
  const currentPage = history.location.pathname;

  const navToggle = () => {
    if (window.$('#mySidenav').hasClass('closed')) {
      window.$('#mySidenav').removeClass('closed');
    } else {
      window.$('#mySidenav').addClass('closed');
    }
  };

  return (
    <>
      <ConnectWalletPopup />
      {/* <span className="open-btn" onClick={openNav}>
        &#9776;{' '}
      </span> */}
      <div className="side-menu closed " id="mySidenav">
        <div className="closemenu" onClick={navToggle}></div>
        <span className="open-btn2">
          <img
            onClick={async () => {
              window.$('#connectWallet').modal('show');
            }}
            src="/images/wallet.svg"
            alt="close menu"
          />
        </span>
        <div className="logo">
          {' '}
          <Link to="/">
            <img src="images/logo.png" alt="" />
          </Link>
        </div>
        <ul className="">
          <li
            className={` ${currentPage === '/' ? 'active' : null}`}
            // data-wow-delay="0.1s"
          >
            <Link to="/">
              {' '}
              <i className="csicon icon-dash"></i>
              <span> DSCVR Dashboard</span>
            </Link>
          </li>

          <li className="">
            <a
              href={process.env.REACT_APP_LAUNCHPAD_URL}
              target="_blank"
              rel="noreferrer"
            >
              <i className="csicon icon-launch"></i>
              <span>DSCVR Launchpad</span>
            </a>
          </li>

          <li
            className={` ${currentPage === '/database' ? 'active' : null}`}
            // data-wow-delay="0.1s"
          >
            <Link to="/database">
              {' '}
              <i className="csicon icon-databse"></i>
              <span> DSCVR Database</span>
            </Link>
          </li>

          {/* <li
            className={` ${currentPage === '/pool-explorer' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <Link to="/pool-explorer">
              {' '}
              <img
                src="images/explorer.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/explorer-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>Pool Explorer</span>
            </Link>
          </li> */}
          {/* <li
            className={` ${currentPage === '/pair-explorer' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <Link to="/pair-explorer?0xbe55c87dff2a9f5c95cb5c07572c51fd91fe0732">
              {' '}
              <img
                src="images/pair-explorer.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/pair-explorer-active.png"
                className="active-img"
                alt=""
              />
              <span> Pair Explorer</span>
            </Link>
          </li> */}
          {/* <li
            className={` ${currentPage === '/big-swap' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            {' '}
            <Link to="/big-swap">
              <img src="images/big-swap.png" className="nonactive-img" alt="" />{' '}
              <img
                src="images/big-swap-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>Big Swap Explorer</span>
            </Link>
          </li> */}

          <li
            className={` ${currentPage === '/NFTzone' ? 'active' : null}`}
            // data-wow-delay="0.1s"
          >
            <Link to="/NFTzone">
              {' '}
              <i className="csicon icon-NFT"></i>
              <span>NFT Zone</span>
            </Link>
          </li>

          {/* <li
            className={` ${currentPage === '/nft-zone' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <a href={() => false}>
              {' '}
              <img src="images/nft.png" className="nonactive-img" alt="" />{' '}
              <img src="images/nft-active.png" className="active-img" alt="" />{' '}
              <span>NFT Zone</span>
              <div className="coming_img">Coming Soon</div>
            </a>
          </li> */}

          {/* <li
            className={` ${currentPage === '/whitelist' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <a href={() => false}>
              {' '}
              <img
                src="images/whitelist.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/whitelist-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>Open Whitelist</span>
              <img
                src="images/get-soon.png"
                alt=""
                style={{
                  position: 'relative',
                  right: 0,
                  bottom: '-15px',
                  height: 40,
                  width: '25%',
                }}
              />
            </a>
          </li> */}

          {/* <li
            className={` ${currentPage === '/NewListing' ? 'active' : null}`}
            // data-wow-delay="0.1s"
          >
            <Link to="/NewListing">
              {' '}
              <img
                src="images/listing.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/listing-active.png"
                className="active-img"
                alt=""
              />
              <span>New Listing</span>
            </Link>
          </li> */}

          {/* <li
            className={` ${currentPage === '/new-listing' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <a href={() => false}>
              {' '}
              <img
                src="images/listing.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/listing-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>New Listing</span>
              <div className="coming_img">Coming Soon</div>
            </a>
          </li> */}
          <li
            className={` ${
              currentPage === '/open-whitelist' ? 'active' : null
            }`}
            // data-wow-delay="0.50s"
          >
            <Link to="/open-whitelist">
              {' '}
              <i className="csicon icon-whitelist"></i>
              <span>Open Whitelist</span>
              {/* <img
                src="images/get-soon.png"
                alt=""
                style={{
                  position: 'relative',
                  right: 0,
                  bottom: '-15px',
                  height: 40,
                  width: '25%',
                }}
              /> */}
            </Link>
          </li>

          <li
            className={` ${currentPage === '/air-drops' ? 'active' : null}`}
            // data-wow-delay="0.50s"
          >
            <Link to="/air-drops">
              {' '}
              <i className="csicon icon-drop"></i>
              <span>Air Drops</span>
              {/* <img
                src="images/get-soon.png"
                alt=""
                style={{
                  position: 'relative',
                  right: 0,
                  bottom: '-15px',
                  height: 40,
                  width: '25%',
                }}
              /> */}
            </Link>
          </li>

          {/* <li
            className={` ${
              currentPage === '/user-account' ? 'active' : null
            }`}
            data-wow-delay="0.50s"
          >
            <Link to="/user-account">
              {' '}
              <img
                src="images/user.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img src="images/user-active.png" className="active-img" alt="" />{' '}
              <span>User Account</span>
            </Link>
          </li> */}
          {/* <li
            className={` ${
              currentPage === '/configuration' ? 'active' : null
            }`}
            data-wow-delay="0.50s"
          >
            <Link to="/configuration">
              {' '}
              <img
                src="images/setting.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/setting-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>Configuration</span>
            </Link>
          </li> */}
          <li className="">
            <a href="https://coin360.com/" target="_blank" rel="noreferrer">
              {' '}
              <i className="csicon icon-heat"></i>
              <span>Crypto Heat Map</span>
            </a>
          </li>
          <li className="">
            <a href="https://lunarcrush.com/" target="_blank" rel="noreferrer">
              {' '}
              <i className="csicon icon-social"></i>
              <span>Trending Social</span>
            </a>
          </li>
          <li className="">
            <a
              href="https://app.uniswap.org/#/swap?inputCurrency=0x3ee6e46f52c475c52040b934745501f2a0cea252"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              <i className="csicon icon-buy"></i>
              <span>Buy DSCVR</span>
            </a>
          </li>
          <li
            className={` ${
              currentPage === '/open-whitelist' ? 'active' : null
            }`}
            // data-wow-delay="0.50s"
          >
            <Link to="/api-page">
              <i className="csicon icon-whitelist"></i>
              <span>Get API</span>
            </Link>
          </li>
          {/* <li className={` ${currentPage === '/bug-bounty' ? 'active' : null}`}>
            <Link to="/bug-bounty">
              {' '}
              <img
                src="images/whitelist.png"
                className="nonactive-img"
                alt=""
              />{' '}
              <img
                src="images/whitelist-active.png"
                className="active-img"
                alt=""
              />{' '}
              <span>Bug Bounty</span>
            </Link>
          </li> */}
        </ul>
        <PaireList />
      </div>
    </>
  );
}

export function PaireList() {
  const auth = useContext(AuthContext);
  const history = useHistory();

  // const accessToken = window.localStorage.getItem('accessToken');
  const [paireList, setPaireList] = useState([]);

  useEffect(() => {
    if (auth.cardWishListData && auth.cardWishListData.length > 0) {
      setPaireList(auth.cardWishListData);
    }
  }, [auth.cardWishListData]);

  // useEffect(() => {
  //   if (auth.userLoggedIn) {
  //     getPairWishList();
  //   }
  // }, [auth.userLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getPairWishList = async () => {
  //   // setIsloading(true);
  //   try {
  //     const res = await axios.get(ApiConfig.cardWishList, {
  //       headers: {
  //         token: accessToken,
  //       },
  //     });
  //     console.log('getPairWishList', res);
  //     if (res.data.response_code !== 200) {
  //       setPaireList([]);
  //     } else {
  //       setPaireList(res.data.result);
  //     }
  //   } catch (err) {
  //     console.log('ERROR', err);
  //   }
  // };

  return (
    <div className="watchlist-menu-div mt-3">
      <div className="page-titel mb-4">
        <img src="images/watchlist.svg" alt="" />{' '}
        <h3>
          <span>My </span> Watchlist{' '}
        </h3>
      </div>
      <div className="table-responsive index-table">
        <table className="table mb-0">
          <tbody>
            {paireList &&
              paireList.length > 0 &&
              paireList.map((data, i) => {
                return (
                  <tr
                    style={{ cursor: 'pointer' }}
                    className={(i + 1) % 2 === 0 ? '' : 'secend-tr'}
                    key={i}
                    onClick={() => {
                      history.push({
                        pathname: '/coin-details',
                        search: `?${data._id}`,
                        state: { data: data },
                      });
                    }}
                  >
                    <td>
                      <h6
                        style={{
                          whiteSpace: 'nowrap',
                          maxWidth: 140,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {data.projectName}
                      </h6>
                    </td>
                    {/* <td className="red-text">$0.050566 </td> */}
                    <td className="">
                      <img src="images/icon.png" alt="" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {paireList && paireList.length === 0 && (
          <div
            className="ml-2"
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            <h6>No records found</h6>{' '}
          </div>
        )}
      </div>
    </div>
  );
}
