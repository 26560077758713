import { Link } from 'react-router-dom';

export default function Page() {
  return (
    <footer
      style={{
        position: 'relative',
        bottom: 0,
        width: '100%',
      }}
    >
      <ul>
        <li>
          <a
            href="https://t.me/DSCVRFinance"
            data-toggle="tooltip"
            title="Telegram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/DSCVR_Finance"
            data-toggle="tooltip"
            title="Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a
            href="https://dscvr-finance.medium.com/"
            data-toggle="tooltip"
            title="Medium"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-medium" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a
            href="https://etherscan.io/address/0x3ee6e46f52c475c52040b934745501f2a0cea252"
            data-toggle="tooltip"
            title="Etherscan"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/eth-icon.png" alt="" className="ml-1" />
          </a>
        </li>
        <li>
          <a
            href="https://dscvr.finance/litepaper/"
            data-toggle="tooltip"
            title="Litepaper"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-book" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <Link
            to={'/terms'}
            style={{ color: 'white' }}
            data-toggle="tooltip"
            title="T&C"
          >
            <i className="fa fa-file" aria-hidden="true"></i>
          </Link>
        </li>
      </ul>
      <p>© DSCVR.Finance 2021 - info@dscvr.finance</p>
    </footer>
  );
}
