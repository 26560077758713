import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/Auth';
import { SUPPORTED_WALLETS } from '../context/connectors/index';
import { CriculerLoader } from './PageLoader';

export default function ConnectWalletPopup() {
  const auth = useContext(AuthContext);
  const [selectedWallet, setSelectedWallet] = useState('METAMASK');
  useEffect(() => {
    if (auth.userLoggedIn) {
      window.$('#connectWallet').modal('hide');
    }
  }, [auth.userLoggedIn]);

  const connectWallet = async (connector) => {
    await auth.connectToWallet(connector);
  };
  return (
    <div
      className="modal fade"
      id={'connectWallet'}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered " role="document">
        <div
          className="modal-content eth-buttons-content"
          style={{
            width: '100%',
            padding: 25,
            backgroundColor: '#10171f',
            Width: 600,
            maxWidth: '100%',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <h5>Please connect your wallet</h5>

          <div className="row mt-3">
            {SUPPORTED_WALLETS.map((item) => {
              return (
                <div className="col-md-6 mt-3">
                  <button
                    key={item.name}
                    className="btn connect mr-0 pt-2"
                    disabled={auth.isLoading}
                    onClick={async () => {
                      window.localStorage.removeItem('walletName');
                      window.localStorage.setItem('walletName', item.name);
                      // window.$('#selectChannel').modal('hide');
                      setSelectedWallet(item.data.name);
                      connectWallet(item.data);
                    }}
                    style={{
                      Width: 250,
                      maxWidth: '100%',
                      minWidth: '100%',
                      display: 'flex',
                      marginLeft: '0px',
                      justifyContent: 'right',
                    }}
                  >
                    <div className="walletImage mr-2">
                      <img
                        src={item.data.iconName}
                        alt={''}
                        width="25"
                        height="25"
                        style={{ marginRight: 0 }}
                        // className="mr-3 mt-1"
                      />{' '}
                    </div>
                    {item.data.name}
                    {auth.isLoading && selectedWallet === item.data.name && (
                      <CriculerLoader />
                    )}
                  </button>
                </div>
              );
            })}{' '}
          </div>

          <div className="mt-3">
            {auth.errorMsg && auth.errorMsg !== '' && (
              <h6 style={{ color: 'red', textAlign: 'center' }}>
                {auth.errorMsg}
                {/* {selectedWallet && ` Please install ${selectedWallet}`} */}
              </h6>
            )}
          </div>

          <div className="mt-3 text-center">
            <button
              onClick={() => window.$('#connectWallet').modal('hide')}
              className="btn connect pl-4 pr-4"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
