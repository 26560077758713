import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import ApiConfig from '../Config/ApiConfig';
import { useWeb3React } from '@web3-react/core';
import { SUPPORTED_WALLETS } from './connectors';

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [adData, setAdData] = useState('');
  const [ethBTCValue, setEthBTCValue] = useState();
  const [topTenCounList, settoptenCounList] = useState();
  const [telegramInfo, setTelegramInfo] = React.useState();
  const [unReadCount, setUnReadCount] = useState(0);
  // const [services, setServices] = useState([]);
  const [cardWishListData, setCardWishListData] = useState([]);
  const [userType, setUserType] = useState('USER');
  const { activate, account, deactivate } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationList, setNotificationList] = useState();
  const [addStatus, setAddStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // NOTIFICATION
  useEffect(() => {
    const web = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL);
    if (isLogin && account && typeof account !== 'undefined') {
      const accessToken = window.localStorage.getItem('accessToken');
      try {
        web.onopen = () => {
          const dataToSend = {
            option: 'notification',
            token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            // console.log('event', event);
            if (event.data !== '[object Promise]' && event.data !== 'null') {
              let obj = JSON.parse(event.data);
              if (obj.data && obj.data.length > 0) {
                setNotificationList(obj.data);
                setUnReadCount(obj.unReadCount);
              } else {
                setNotificationList([]);
                setUnReadCount(0);
              }
            }
          };
        };
        return () => {
          setNotificationList();
          setUnReadCount(0);
          web.close();
        };
      } catch (err) {
        console.log('err', err);
      }
    }
  }, [userData, isLogin, account]); // eslint-disable-line react-hooks/exhaustive-deps

  // const connectToWallet = async () => {
  //   try {
  //     if (window.ethereum) {
  //       const web3 = (window.web3 = new Web3(window.ethereum));
  //       await window.ethereum.enable();
  //       const accounts = await web3.eth.getAccounts();
  //       // const networkId = await web3.eth.net.getId();
  //       await logInHandler(accounts[0]);
  //     } else {
  //       alert('Please login into Metamask');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const connectToWallet = (data) => {
    const connector = data.connector;
    setErrorMsg('');
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log('error', error.message);
        setErrorMsg(error.message + ' Please install ' + data.name);
        activate(connector);
      }
    });
  };

  useEffect(() => {
    if (window.localStorage.getItem('walletName')) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.localStorage.getItem('walletName')
      );
      connectToWallet(selectectWalletDetails[0].data);
    }
  }, []);

  useEffect(() => {
    if (account && typeof account !== 'undefined') {
      logInHandler(account);
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAdData = async () => {
    try {
      const res = await axios.get(ApiConfig.advertisementData);
      if (res.data.response_code !== 200) {
        setAdData([]);
      } else {
        setAdData(res.data.result);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const getCardWishList = async (accessToken) => {
    // setIsloading(true);
    try {
      const res = await axios.get(ApiConfig.cardWishList, {
        headers: {
          token: accessToken,
        },
      });
      if (res.data.response_code !== 200) {
        setCardWishListData([]);
      } else {
        setCardWishListData(res.data.result);
      }
    } catch (err) {
      getCardWishList(accessToken);
      console.log('ERROR', err);
    }
  };

  const getPairHourDatas = async () => {
    try {
      const res = await axios.get(ApiConfig.pairHourDatas);
      if (res.data.responseCode !== 200) {
        settoptenCounList([]);
      } else {
        let data = res.data.responseResult;
        var resArr = [];
        data.forEach(function (item) {
          var i = resArr.findIndex(
            (x) => x.pair.token0.symbol === item.pair.token0.symbol
          );
          if (i <= -1) {
            resArr.push({ id: item.id, pair: item.pair });
          }
        });
        let dummyArr = [];
        resArr.length > 0 &&
          resArr.forEach((element) => {
            if (element.pair.token0.symbol === 'WETH') {
              dummyArr.push({
                token: element.pair.token1,
                pairId: element.pair.id,
              });
            } else {
              dummyArr.push({
                token: element.pair.token0,
                pairId: element.pair.id,
              });
            }
          });
        // resArr.filter((data) => data.pair.token0.symbol !== 'WETH');
        window.localStorage.setItem('pairHourDatas', JSON.stringify(dummyArr));
        settoptenCounList(dummyArr);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  // const getSwapDetailsListHandler = async () => {
  //   try {
  //     const res = await axios.get(ApiConfig.getSwapDetailsList, {});
  //     console.log('res.data', res.data);
  //     setServices(res.data.responseResult);
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   getSwapDetailsListHandler();
  // }, []);

  const getChatInfoHandler = async () => {
    const res = await axios.get(ApiConfig.getChatInfoForAllChannel);
    if (res.data.response_code !== 200) {
    } else {
      setTelegramInfo(res.data.result);

      //   setTimeout(() => {
      //     custoomCSS();
      //   }, 100);
    }
  };

  const getMarketCap = async () => {
    try {
      const res = await axios.get(`${ApiConfig.getMarketCapSymbol}/ETH,BTC`);
      if (res.data.responseCode !== 200) {
      } else {
        setEthBTCValue(res.data.responseResult.data);
        window.localStorage.setItem(
          'ethBTCValue',
          JSON.stringify(res.data.responseResult.data)
        );
      }
    } catch (err) {
      console.log('ERROR', err);
    }
  };

  const logInHandler = async (walletAddress) => {
    setIsLoading(true);
    setErrorMsg('');
    try {
      const res = await axios.post(ApiConfig.connectWallet, {
        walletAddress: walletAddress,
      });
      setIsLoading(false);
      if (res.data.response_code !== 200) {
        disconnectWallethandler();
        setErrorMsg(res.data.response_message);
      } else {
        setSession(res.data.result.token);
        setIsLogin(true);
        setUserData(res.data.result);
        setUserType(res.data.result.userType);
        getCardWishList(res.data.result.token);
      }
    } catch (err) {
      console.log('ERROR', err);
      logInHandler(walletAddress);
    }
  };

  const getaddStatusHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getadShow);
      if (res.data.response_code === 200) {
        setAddStatus(res.data.result[0].addStatus);
        console.log(
          'res.data.result[0].addStatus',
          res.data.result[0].addStatus
        );
      }
    } catch (error) {
      getaddStatusHandler();
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    getMarketCap();
    getaddStatusHandler();
    getChatInfoHandler();
    getPairHourDatas();
    getAdData();
  }, []);

  // //ACCOUNT CHANGE
  // useMemo(
  //   () =>
  //     window.ethereum &&
  //     window.ethereum.on('accountsChanged', async function (accounts) {
  //       if (accounts.length > 0) {
  //         window.location.reload();
  //       } else {
  //         localStorage.removeItem('accessToken');
  //         alert('Please Connect to wallet');
  //         setUserData({});
  //       }
  //     }),
  //   [] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  // //NETWORK CHANGE
  // useMemo(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on('networkChanged', async function (networkId) {
  //       // await connectToWallet();
  //       window.location.reload();
  //       // setNetworkId(networkId);
  //     });
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disconnectWallethandler = () => {
    setSession(null);
    setIsLogin(false);
    setUserData({});
    setUserType('USER');
    deactivate();
  };

  let data = {
    addStatus,
    errorMsg,
    userLoggedIn: isLogin,
    adData: adData,
    isLoading: isLoading,
    userType: userType,
    telegramInfo: telegramInfo,
    ethBTCValue: ethBTCValue,
    topTenCounList: topTenCounList,
    cardWishListData: cardWishListData,
    notificationList: notificationList,
    unReadNotificaionCount: unReadCount,
    updateaddStatusHandler: () => getaddStatusHandler(),
    getCardWishList: (token) => getCardWishList(token),
    connectToWallet: (connector) => connectToWallet(connector),
    userData,
    disconnectToWallet: () => {
      disconnectWallethandler();
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
