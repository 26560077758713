// let url;
// let IPUrl = 'http://182.72.203.245:1826/api/v1/';
let DomainURL = `${process.env.REACT_APP_API_URL}/api/v1/`;

let url = `${DomainURL}user`;
let adminUR = `${DomainURL}admin`;
let message = `${DomainURL}message`;
let event = `${DomainURL}event`;
let notification = `${DomainURL}notification`;
let report = `${DomainURL}report`;

const ApiConfig = {
  //USER
  getSwapDetailsList: `${url}/getSwapDetailsList  `,
  getBasicAndAdvanceList: `${url}/getBasicAndAdvanceList`,
  getSwapPairList: `${url}/getSwapPairList`,
  getLatestDetailsOfPoolsTransaction: `${url}/getLatestDetailsOfPoolsTransaction`,
  getBiggestSwapDetails: `${url}/getBiggestSwapDetails`,
  cmcTopTen: `${url}/cmcTopTen`,
  getLiveETHPrice: `${url}/getLiveETHPrice`,
  getMarketCapSymbol: `${url}/getMarketCapSymbol`,
  addData: `${url}/addData`,
  getDataListing: `${url}/getDataListing`,
  getLatestData: `${url}/getLatestData`,
  downVote: `${url}/downVote`,
  upVote: `${url}/upVote`,
  viewData: `${url}/viewData`,
  login: `${url}/login`,
  signup: `${url}/signup`,
  cardWishUnWishedData: `${url}/cardWishUnWishedData`,
  getDataListingWithAuth: `${url}/getDataListingWithAuth`,
  upVoteList: `${url}/upVoteList`,
  downVoteList: `${url}/downVoteList`,
  pairWishUnWishedData: `${url}/pairWishUnWishedData`,
  pairWishList: `${url}/pairWishList`,
  connectWallet: `${url}/connectWallet`,
  cardWishList: `${url}/cardWishList`,
  pairHourDatas: `${url}/pairHourDatas`,
  featuredListData: `${url}/featuredListData`,
  advertisementData: `${url}/advertisementData`,
  listTweetUser: `${url}/listTweet`,
  searchPairList: `${url}/searchPairList`,
  getNFTZoneList: `${url}/getNFTZoneList`,
  getNewListing: `${url}/getNewListing`,
  hideUnhideData: `${url}/hideUnhideData`,
  editEvent: `${url}/editEvent`,
  viewDataWithoutAuth: `${url}/viewDataWithoutAuth`,

  //ADMIN
  featuredListDataAdmin: `${adminUR}/featuredListData`,
  requestDataList: `${adminUR}/requestDataList`,
  viewRequestData: `${adminUR}/viewRequestData`,
  approveData: `${adminUR}/approveData`,
  addFeatureUnfeatureData: `${adminUR}/addFeatureUnfeatureData`,
  getDataList: `${adminUR}/getDataList`,
  rejectData: `${adminUR}/rejectData`,
  requestNFTList: `${adminUR}/requestNFTList`,
  viewRequestNFT: `${adminUR}/viewRequestNFT`,
  approveNFT: `${adminUR}/approveNFT`,
  rejectNFT: `${adminUR}/rejectNFT`,
  getBugReportList: `${adminUR}/getBugReportList`,
  setBugStatus: `${adminUR}/setBugStatus`,
  setBugPriority: `${adminUR}/setBugPriority`,
  editBugReport: `${adminUR}/editBugReport`,
  setAdshow: `${adminUR}/setAdshow`,
  getadShow: `${adminUR}/getadShow`,

  //Tweet
  addTweet: `${adminUR}/addTweet`,
  listTweet: `${adminUR}/listTweet`,

  //event
  addCreator: `${event}/addCreator`,
  creatorList: `${event}/creatorList`,
  addEvent: `${event}/addEvent`,
  listEvent: `${event}/listEvent`,
  hideUnhideEvent: `${event}/hideUnhideEvent`,
  eventFeatureUnFeature: `${event}/eventFeatureUnFeature`,
  eventFeatureList: `${event}/eventFeatureList`,
  eventSearchByDate: `${event}/eventSearchByDate`,
  eventSearchByMonth: `${event}/eventSearchByMonth`,
  viewEvent: `${event}/viewEvent`,

  //Message
  getTelegramHistory: `${message}/getTelegramHistory`,
  getChatInfo: `${message}/getChatInfo`,
  addChannelName: `${message}/addChannelName`,
  getChannelList: `${message}/getChannelList`,
  getChatInfoForAllChannel: `${message}/getChatInfoForAllChannel`,
  deleteChannelName: `${message}/deleteChannelName`,

  //Notification
  readNotification: `${notification}/readNotification`,

  //REPORT
  addBugReport: `${report}/addBugReport`,
  listBugReport: `${report}/listBugReport`,

  //Ad
  addAdvertisement: `${adminUR}/addAdvertisement`,
  listAdvertisement: `${adminUR}/listAdvertisement`,
  enableDisableAdvertisement: `${adminUR}/enableDisableAdvertisement`,
};

export default ApiConfig;
