import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageLayout from './components/PageLayout';
import PageLoader from './components/PageLoader';
import AuthContext from './context/Auth';

export default function App() {
  return (
    <AuthContext>
      <Router>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <PageLayout>
              <Route
                path="/user-account-connect"
                exact={true}
                component={lazy(() => import('./screens/userAccountConnect'))}
              />
              <Route
                path="/user-account"
                exact={true}
                component={lazy(() => import('./screens/UserAccount'))}
              />
              <Route
                path="/configuration"
                exact={true}
                component={lazy(() => import('./screens/configuration'))}
              />
              <Route
                path="/database"
                exact={true}
                component={lazy(() => import('./screens/Database'))}
              />
              <Route
                path="/add-database"
                exact={true}
                component={lazy(() => import('./screens/AddDatabase'))}
              />
              <Route
                path="/open-whitelist"
                exact={true}
                component={lazy(() => import('./screens/Whitelist'))}
              />
              <Route
                path="/air-drops"
                exact={true}
                component={lazy(() => import('./screens/AirDrops'))}
              />
              <Route
                path="/coin-details"
                exact={true}
                component={lazy(() => import('./screens/CoinDetails'))}
              />
              <Route
                path="/big-swap"
                exact={true}
                component={lazy(() => import('./screens/BigSwap'))}
              />
              <Route
                path="/pool-explorer"
                exact={true}
                component={lazy(() => import('./screens/PoolExplorer'))}
              />
              <Route
                path="/pair-explorer"
                exact={true}
                component={lazy(() => import('./screens/PairExplorer'))}
              />
              <Route
                path="/api-page"
                exact={true}
                component={lazy(() => import('./screens/APIPage'))}
              />
              <Route
                path="/api-fee"
                exact={true}
                component={lazy(() => import('./screens/ApiFee'))}
              />
              <Route
                path="/plans-to-grow"
                exact={true}
                component={lazy(() => import('./screens/PlansToGrow'))}
              />
              <Route
                path="/my-profile"
                exact={true}
                component={lazy(() => import('./screens/MyProfile'))}
              />
              <Route
                path="/NFTzone"
                exact={true}
                component={lazy(() => import('./screens/NFTzone'))}
              />
              <Route
                path="/NFTDetails"
                exact={true}
                component={lazy(() => import('./screens/NFTDetails'))}
              />
              <Route
                path="/edit-event"
                exact={true}
                component={lazy(() => import('./screens/EditEvent'))}
              />
              <Route
                path="/terms"
                exact={true}
                component={lazy(() => import('./screens/Terms'))}
              />
              <Route
                path="/api-docs"
                exact={true}
                component={lazy(() => import('./screens/APIDocs/index'))}
              />
              {/* <Route
                path="/bug-bounty"
                exact={true}
                component={lazy(() => import('./screens/BugBounty/index'))}
              /> */}
              {/* <Route
                path="/GoogleAnalyticsPopup"
                exact={true}
                component={lazy(() =>
                  import('./components/GoogleAnalyticsPopup')
                )}
              /> */}

              {/* <Route
                path="/NewListing"
                exact={true}
                component={lazy(() => import('./screens/NewListing'))}
              /> */}
              <Route
                path="/404"
                component={lazy(() => import('./screens/PageNotFound'))}
              />
              <Route
                exact={true}
                path="/"
                component={lazy(() => import('./screens/home'))}
              />
              {/* <Route component={() => Redirect('/404')} /> */}
            </PageLayout>
          </Switch>
        </Suspense>
      </Router>
    </AuthContext>
  );
}
